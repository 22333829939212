<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="col-sm-7 offset-sm-3">
      <div class="card login-card">
        <div class="row no-gutters">
          <!-- <div class="col-sm-5">
            <img src="assets/images/Backgrounds/ship-bg.jpg" alt="login" class="login-card-img">
          </div> -->
          <div class="col-sm-12">
            <div class="card-body">
              <form action="#!" class="theme-forms theme-form" [formGroup]="forgotForm" (ngSubmit)="onForgotFormSubmit()">
                <div class="col-sm-10 offset-sm-1">
                  <div class="brand-wrapper text-center">
                    <img src="assets/images/logo/logomark-blue.svg" alt="logo" class="logo">

                  </div>
                  <h6 class="text-center">FORGOT PASSWORD</h6>
                  <p class="text-center f-11">If you have forgotten your password you can reset it here </p>
                  <div class="form-group">
                    <label for="email" class="control-label">Email address</label>
                    <input type="email" name="email" id="email" class="form-control" formControlName="email" placeholder="Email address"
                      placeholder="Email address">
                      <div *ngIf="forgotForm.controls.email.errors?.required && formSubmit" class="text text-danger mt-1">
                        This field is required.
                    </div>
                    <div *ngIf="forgotForm.controls.email.errors?.email && formSubmit" class="text text-danger mt-1">
                        Invalid email format.
                    </div>
                    <div *ngIf="formSubmit && forgotForm.controls.email.errors?.backenderrors" class="text-danger">{{(forgotForm.controls.email.errors.errmsg)?forgotForm.controls.email.errors.errmsg:'Invalid mail.'}}</div>

                  </div>
                  <div class="form-group">
                    <div class="text-center">
                      <button class="btn  login-btn mb-4" type="submit">Submit</button>
                    </div>
                  </div>
                  <a routerLink="/auth/login" class="forgot-password-link">Back to login</a>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>