import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NavService, Menu } from '../../services/nav.service';
import { AuthService } from '../../services/firebase/auth.service';
import { LayoutService } from '../../services/layout.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment'; 
import { AuthStateService } from '../../../shared/auth/auth-state.service'; 
import { TokenService } from '../../auth/token.service';
const env_datas = environment.firebase;
const image_url =env_datas.image_url;

declare var require
const Swal = require('sweetalert2')
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: []
})
export class HeaderComponent implements OnInit {

  public username:any;
  public userole:any;
  public profImgPath:any;
  public menuItems: Menu[];
  public elem: any;
  public items: Menu[];
  public megaItems: Menu[];

  public language: boolean = false;
  public search: boolean = false;
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public text: string;

  public dark: boolean = this.layout.config.color.mix_background_layout == 'dark-only' ? true : false;

  constructor(public layout: LayoutService,
    public navServices: NavService, @Inject(DOCUMENT) private document: any,
    private modalService: NgbModal,public token: TokenService,private authState: AuthStateService,
    public authService: AuthService,private router: Router) {
  }

  ngOnInit() {
    this.elem = document.documentElement;
    this.navServices.items.subscribe(menuItems => this.items = menuItems);
    this.navServices.megaItems.subscribe(megaItems => this.megaItems = megaItems);
    let userdata = JSON.parse(localStorage.getItem('mp_loginuser'));
    if(userdata!=null){
      this.username = userdata.fname+' '+userdata.lname;
      if(userdata.imgPath != null){
        this.profImgPath = image_url+userdata.imgPath;
      }else{
        // this.profImgPath = base_url+'img/no-image.jpg';
        this.profImgPath = "assets/images/user/user.png";
      }
      if(userdata.role==0){
        this.userole= "Super Admin";
      }
      
    }
  }

 
  megaMenuToggle() {
    this.navServices.megaMenu = !this.navServices.megaMenu;
    if(window.innerWidth < 991) { 
      this.navServices.collapseSidebar = true;
    }
  }
  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
  }

  layoutToggle() {
    this.dark = !this.dark;
    this.layout.config.color.mix_background_layout = this.dark ? 'dark-only' : 'light';
  }

  searchToggle() {
    this.search = !this.search;
    this.language = false;
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return this.menuItems = [];
    let items = [];
    term = term.toLowerCase();
    this.items.filter(menuItems => {
      if (!menuItems?.title) return false
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false
      menuItems.children.filter(subItems => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon
          items.push(subItems);
        }
        if (!subItems.children) return false
        subItems.children.filter(suSubItems => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon
            items.push(suSubItems);
          }
        })
      })
      this.checkSearchResultEmpty(items)
      this.menuItems = items
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    document.getElementsByTagName('body')[0].classList.add('offcanvas');
  }

  removeFix() {
    this.searchResult = false;
    this.text = "";
    document.getElementsByTagName('body')[0].classList.remove('offcanvas');
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.megaItems.forEach(a => {
        if (this.megaItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }
  backToLoginAlert(logout){
    this.modalService.open(logout, {backdropClass:"light-blue-backdrop", windowClass: "my-class", size: "md" });
    }


    statuschange() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        
        text: 'Are you leaving?',
        icon: 'warning',        
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
        
      }).then((result) => {
        if (result.value) {          
          this.router.navigateByUrl('auth/login')
        } else if (       
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your status change cancelled :)',
            'error'
          )
        }
      })
    }
    signout()
    {
      Swal.fire({
        title: 'Are you sure want to logout?',
        //text: 'You will not be able to recover this file!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#DD6B55',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'      
      }).then((result) => {
        if (result.value) {
          this.authState.setAuthState(true);
          this.token.removeToken();
          this.router.navigate(['auth/login']);
          localStorage.clear();
        }else if (result.dismiss === Swal.DismissReason.cancel) {
         
        }
      })
    }
}
