<div class="container-fluid justify-content-center">
  <!-- Reset Password page start-->
  <div class="authentication-main mt-0">
    <div class="row">
      <div class="col-md-12 p-0">
        <div class="auth-innerright auth-minibox">
          <div class="authentication-box auth-minibox1">
            <div class="card mt-4 p-4 cardradius">
              <form class="theme-form" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
                <div class="  " style=" align-items: center; display: flex; justify-content: center;">
                  <img src="assets/images/logo/logomark-blue.svg" class="mb-3 " style="width: 60% ; " alt="">
                </div>
                <h6 class=" text-center">RESET PASSWORD</h6>
                <p class="text-center f-11">You can reset your password here
                </p>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="control-label">New password</label>
                      <div class="input-group">
                        <input type="password" name="password" id="password" formControlName="password"  #password1 class="form-control"
                          placeholder="Type new password">
                        <div class="input-group-append" (click)="changeType(password1, 1)">
                          <span class="input-group-text"><i [ngClass]="toggle1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                              style="font-size: 11px;"></i></span>
                        </div>
                      </div>
                      <div *ngIf="formSubmit && changePasswordForm.controls.password.errors?.required"
                        class="text text-danger mt-1">This field is required.</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="control-label">Verify password</label>
                      <div class="input-group">
                        <input type="password" name="c_password" id="c_password"  formControlName="password_confirmation"  #password2 class="form-control"
                          placeholder="Confirm new password">
                        <div class="input-group-append" (click)="changeType(password2, 2)">
                          <span class="input-group-text"><i [ngClass]="toggle2 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                              style="font-size: 11px;"></i></span>
                        </div>
                      </div>
                      <div
                        *ngIf="formSubmit && changePasswordForm.controls.password_confirmation.errors?.required"
                        class="text text-danger mt-1">
                        This field is required.
                      </div>
                      <div
                        *ngIf="formSubmit && changePasswordForm.controls.password_confirmation.errors?.notEquivalent"
                        class="text text-danger mt-1">
                        Your passwords mismatch.
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-12">
                    <div class="">
                      <button class="btn btncolor btn-sm pull-right" type="submit">Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Reset Password page end-->
</div>