<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <p class="mb-0">Copyright {{ today | date:'y'}} © McPhee Freight.</p>
    </div>
    <div class="col-md-6">
      <p class="pull-right mb-0">Developed with <a class="text-primary" href="http://ishtechnologies.com.au" target="_blank">ish technologies</a></p>
    </div>
  </div>
</div>