// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: 'Your Api Key',
    authDomain: 'Your Auth Domain',
    databaseURL: 'Your Database Url',
    projectId: 'Your Project Id',
    storageBucket: 'Your StorageBucket url',
    messagingSenderId: 'Your Sender Id',

    // base_url : "http://127.0.0.1:8000/",
    // api_url:"http://127.0.0.1:8000/api/",
    // app_url : "http://localhost:4200",
    // link : "http://localhost:4200",
    // image_url: "http://127.0.0.1:8000/uploads/",

    // base_url: 'http://ishtech.com.au/mc_phee/',
    // api_url: 'http://ishtech.com.au/mc_phee/api/',
    // app_url: 'http://ishtech.com.au/mcphee/',
    // image_url: 'http://ishtech.com.au/mc_phee/uploads/',

    base_url: 'https://mcpheefreightsoftware.com.au/mc_phee/',
    api_url: 'https://mcpheefreightsoftware.com.au/mc_phee/api/',
    app_url: 'https://mcpheefreightsoftware.com.au/mcphee/',
    link: 'https://mcpheefreightsoftware.com.au',
    image_url: 'https://mcpheefreightsoftware.com.au/mc_phee/uploads/',

    // base_url: 'https://mcpheefreightsoftware.com.au/mc_pheeprod/',
    // api_url: 'https://mcpheefreightsoftware.com.au/mc_pheeprod/api/',
    // app_url: 'https://mcpheefreightsoftware.com.au/mcpheeprod/',
    // link: 'https://mcpheefreightsoftware.com.au/mcpheeprod',
    // image_url: 'https://mcpheefreightsoftware.com.au/mc_pheeprod/uploads/',

    // base_url: 'https://mcpheefreightsoftware.com.au/mc_pheeproduction/',
    // api_url: 'https://mcpheefreightsoftware.com.au/mc_pheeproduction/api/',
    // app_url: 'https://mcpheefreightsoftware.com.au/mcpheeproduction/',
    // link: 'https://mcpheefreightsoftware.com.au/mcpheeproduction',
    // image_url: 'https://mcpheefreightsoftware.com.au/mc_pheeproduction/uploads/',

    // base_url: 'https://ishtech.digital/mc_phee/',
    // api_url: 'https://ishtech.digital/mc_phee/api/',
    // app_url: 'https://ishtech.digital/mcphee/',
    // link: 'https://ishtech.digital',
    // image_url: 'https://ishtech.digital/mc_phee/uploads/',
    },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
