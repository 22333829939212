import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  visible?: number[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  public megaMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      path: '/dashboard',
      title: 'Dashboard',
      icon: 'airplay',
      type: 'link',
      active: false,
      visible: [0, 1, 2],
    },
    {
      path: '/search-booking',
      title: 'Search Booking',
      icon: 'search',
      type: 'link',
      active: false,
      visible: [0, 1, 2],
    },
    {
      path: '/booking-list',
      title: 'Bookings',
      icon: 'book',
      type: 'link',
      active: false,
      visible: [0, 1, 2],
    },
    {
      path: '/bulk-updates',
      title: 'Bulk Updates',
      icon: 'book',
      type: 'link',
      active: false,
      visible: [0, 1, 2],
    },
    {
      path: '/users-list',
      title: 'Users',
      icon: 'user',
      type: 'link',
      active: false,
      visible: [0, 1],
    },
    {
      title: 'Vessels',
      icon: 'navigation',
      type: 'sub',
      active: false,
      visible: [0, 1, 2],
      children: [
        {
          path: '/vessels-list',
          title: 'Add Vessel',
          type: 'link',
          visible: [0, 1, 2],
        },
        {
          path: '/new-schedule',
          title: 'Vessel allocation',
          type: 'link',
          visible: [0, 1, 2],
        },
      ],
    },
    {
      path: '/account-list',
      title: 'Accounts',
      icon: 'file-text',
      type: 'link',
      active: false,
      visible: [0, 1, 2],
    },
    {
      path: '/vendors-list',
      title: 'Vendors',
      icon: 'users',
      type: 'link',
      active: false,
      visible: [0, 1, 2],
    },
    {
      title: 'Blink',
      icon: 'map-pin',
      type: 'sub',
      active: false,
      visible: [0, 1, 2],
      children: [
        {
          path: '/delivery-vendor-list',
          title: 'Delivery vendor',
          icon: 'users',
          type: 'link',
          active: false,
          visible: [0, 1, 2],
        },
        {
          path: '/collection-point-list',
          title: 'Collection points',
          icon: 'map-pin',
          type: 'link',
          active: false,
          visible: [0, 1, 2],
        },
        {
          path: '/delivery-booking-list',
          title: 'Delivery booking',
          icon: 'truck',
          type: 'link',
          active: false,
          visible: [0, 1, 2],
        },
      ],
    },
    {
      title: 'Reporting',
      icon: 'edit',
      type: 'sub',
      active: false,
      visible: [0, 1, 2],
      children: [
        {
          path: '/reporting-list',
          title: 'Reporting',
          type: 'link',
          visible: [0, 1, 2],
        },
        {
          path: '/template-list',
          title: 'Template',
          type: 'link',
          visible: [0, 1, 2],
        },
      ],
    },
    {
      title: 'Settings',
      icon: 'settings',
      type: 'sub',
      active: false,
      visible: [0, 1, 2],
      children: [
        {
          path: '/settings',
          title: 'Settings',
          type: 'link',
          visible: [0, 1, 2],
        },
        {
          path: '/cargo-list',
          title: 'VEHICLE MASTER',
          type: 'link',
          visible: [0, 1, 2],
        },
        {
          path: '/port-list',
          title: 'Ports',
          type: 'link',
          visible: [0, 1, 2],
        },
        {
          path: '/cargo-type',
          title: 'Cargo Type',
          type: 'link',
          visible: [0, 1, 2],
        },
        {
          path: '/shipping-line',
          title: 'Shipping line',
          type: 'link',
          visible: [0, 1, 2],
        },
        {
          path: '/view-transport',
          title: 'Frequent transport',
          type: 'link',
          visible: [0, 1, 2],
        },
        {
          path: '/billing-code',
          title: 'Billing code',
          type: 'link',
          visible: [0, 1, 2],
        },
      ],
    },
  ];

  MEGAMENUITEMS: Menu[] = [];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
}
