import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  toggle1: boolean = false;
  toggle2: boolean = false;
  formSubmit:boolean=false;
  changeType(input_field_password, num){
    if(input_field_password.type=="password")
      input_field_password.type = "text";
    else
      input_field_password.type = "password";

    if(num == 1)
      this.toggle1 = !this.toggle1;
    else
      this.toggle2 = !this.toggle2;
  }
  constructor(activeRoute: ActivatedRoute,public fb: FormBuilder,private toaster: ToastrService,
    public authService: AuthService,public router: Router) {      
    this.changePasswordForm = this.fb.group({
      email: [''],
      password: ['',Validators.required],
      password_confirmation: ['',Validators.required],
      passwordToken: ['']
    },{validator: this.checkIfMatchingPasswords('password', 'password_confirmation')});

    activeRoute.params.subscribe((val) => {
      let token= (val.token)??null;
      this.changePasswordForm.controls['passwordToken'].setValue(token);
    });

     }

  ngOnInit() {
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
          passwordConfirmationInput = group.controls[passwordConfirmationKey];      
        if (passwordInput.value !== passwordConfirmationInput.value && passwordInput.value !== '' && passwordConfirmationInput.value!='') {
          return passwordConfirmationInput.setErrors({notEquivalent: true})
        }
        else {
          if(passwordConfirmationInput.value==''){
            return passwordConfirmationInput.setErrors({required: true});
          }
            return passwordConfirmationInput.setErrors(null);
        }
    }
  }

  onSubmit(){
    this.formSubmit =true;
    if(this.changePasswordForm.valid){
      this.authService.resetPassword(this.changePasswordForm.value).subscribe(
        (result:any) => {  
          let msg = (result.message)?result.message:'Password reset successfully.'; 
          this.toaster.success(msg); 
          this.formSubmit=false;             
          this.changePasswordForm.reset();       
          this.router.navigate(['auth/login']);
        },(err) => {
          console.log(err);
          let errormsg = (err.error.message)??'Failed to reset your password.';
          this.toaster.warning(errormsg); 
        }
      );
    }
    
  }

}
