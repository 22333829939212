import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/auth/auth.service';
import { TokenService } from '../../shared/auth/token.service';
import { AuthStateService } from '../../shared/auth/auth-state.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  toggle1: boolean = false;
  public remember:any =false;
  public isSubmit:boolean =false;
  public newUser = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  Email:any =null;
  Password:any =null;
  constructor(public authService: AuthService, private fb: FormBuilder,private token: TokenService,
    private route: ActivatedRoute,public router: Router,private authState: AuthStateService,private _coockieservice:CookieService) {
      // const url = this.route.snapshot.url;  
      if(_coockieservice.get('remember')!== undefined){
        if(_coockieservice.get('remember') === 'YES'){
          this.Email = this._coockieservice.get('email');
          this.Password =this._coockieservice.get('password');
          this.remember =true;
          // this.onSubmit();
        }
      }
      // const url = this.route.snapshot.url;
      // if (url[0].path === 'simple-login') {
      //   this.signup = false;
      // }
      this.loginForm = this.fb.group({
        email: [this.Email, [Validators.required, Validators.email]],
        password: [this.Password, Validators.required],
        remember:[this.remember],
      });
  }

  ngOnInit() {
  }
  changeType(input_field_password, num){
    if(input_field_password.type=="password")
      input_field_password.type = "text";
    else
      input_field_password.type = "password";

    if(num == 1)
      this.toggle1 = !this.toggle1;   
  }
  login(data:any) {
    this.isSubmit =true;
    if(this.loginForm.valid){
      this.onSubmit();
    }
  }
  onSubmit(){   
    let userdetails=null;
    this.authService.signin(this.loginForm.value).subscribe(
      result => {
        if (result) {
          localStorage.setItem('mp_loginuser', JSON.stringify(result.user));          
          localStorage.setItem('saved', new Date().toString());     
          userdetails=  result.user;
        } else {
          localStorage.setItem('mp_loginuser', null);         
          localStorage.setItem('saved',null);
          userdetails = null;
        }
        this.responseHandler(result); 
      },
      error => {        
        if(error.error.type==1){
          let errmsg = (error.error.error)??'Invalid mail id.';
          this.loginForm.controls.email.setErrors({'backenderrors': true,'errmsg':errmsg},);
        }else if(error.error.type==2){
          let errmsg = (error.error.error)??'Invalid password.';
          this.loginForm.controls.password.setErrors({'backenderrors': true,'errmsg':errmsg},);
        }else{
          this.loginForm.controls.email.setErrors({'backenderrors':null},);
          this.loginForm.controls.password.setErrors({'backenderrors':null},);
        }           
        localStorage.setItem('mp_loginuser', null);
        JSON.parse(localStorage.getItem('mp_loginuser'));   
        localStorage.setItem('saved',null);  
      },() => {
        this.authState.setAuthState(true);
        if(this.loginForm.value.remember === true){
          this._coockieservice.set('remember','YES'); 
          this._coockieservice.set('email',this.loginForm.value.email); 
          this._coockieservice.set('password',this.loginForm.value.password); 
        }else{
          this._coockieservice.set('remember','NO'); 
          this._coockieservice.set('email',null); 
          this._coockieservice.set('password',null);
        }
       
        this.loginForm.reset();
        if(userdetails !=null){
          this.isSubmit =false;        
          this.router.navigate(['dashboard']);         
        }
        
      });
  }
  responseHandler(data){
    this.token.handleData(data.access_token);
  }

}
