<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
      <div class="card login-card">
        <div class="row no-gutters">         
          <div class="col-sm-12">
            <div class="card-body">

              <form class="theme-forms theme-form" action="#!" [formGroup]="loginForm" *ngIf="!newUser">

                <div class="col-sm-10 offset-sm-1">
                  <div class="brand-wrapper text-center">
                   <div class="row">
                     <div class="col-sm-12">
                      <!-- <img src="assets/images/logo/logo.png" alt="logo" class="logo"> -->
                                            <img src="assets/images/logo/logomark-blue.svg" alt="logo" class="logo">

                     </div>
                   </div>
                  </div>
                  <h6 class="login-card-description text-center">LOGIN</h6>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <label for="email" class="control-label">Email address</label>
                      <input type="email" name="email" id="email" class="form-control" formControlName="email"
                        placeholder="Email address">
                        <div *ngIf="isSubmit && loginForm.controls.email.errors?.required"
                        class="text text-danger mt-1">
                        This field is required.
                      </div>
                      <div *ngIf="isSubmit && loginForm.controls.email.errors?.email"
                        class="text text-danger mt-1">
                        Invalid Email Format.
                      </div>
                      <div *ngIf="isSubmit &&  loginForm.controls.email.errors?.backenderrors" class="text-danger">{{(loginForm.controls.email.errors.errmsg)?loginForm.controls.email.errors.errmsg:'This email id is invalid!'}}</div>
                    </div>
                  </div>
                  <div class="row form-group mb-4">
                    <div class="col-sm-12">
                      <label for="password" class="control-label">Password</label>
                      <div class="input-group">
                        <input type="password" id="password" #password1 class="form-control" placeholder="Password"
                          formControlName="password">
                        <div class="input-group-append" (click)="changeType(password1, 1)">
                          <span class="input-group-text"><i [ngClass]="toggle1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                              style="font-size: 11px;"></i></span>
                        </div>
                      </div>
                      <div *ngIf="isSubmit && loginForm.controls.password.errors?.required"
                        class="text text-danger mt-1">
                        This field is required.
                      </div>
                      <div *ngIf="isSubmit && loginForm.controls.password.errors?.backenderrors" class="text-danger">{{(loginForm.controls.password.errors.errmsg)?loginForm.controls.password.errors.errmsg:'This password is invalid!'}}</div>
                    </div>
                  </div>
                  <div class="form-group row check">
                    <div class="col-sm-12">
                      <label class="control-label" for="chk-ani">
                        <input class="checkbox_animated" id="" formControlName="remember" type="checkbox">Remember me
                      </label>
                    </div>
                  </div>
                  <div class="text-center">
                    <button class="btn btn-sm  login-btn mb-4" [class.loader--text]="authService.showLoader"
                      [disabled]="!loginForm.valid || authService.showLoader" (click)="login(loginForm.value)"
                      type="submit"><span>{{ authService.showLoader ? '' : 'Login' }}</span>
                    </button>
                  </div>

                  <a routerLink="/forgot-password" class="forgot-password-link">Forgot password?</a>
                  <!-- <p class="login-card-footer-text">Don't have an account? <a href="#!" class="text-reset">Register here</a></p> -->

                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>