import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  
  constructor(public authService: AuthService,
    public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, 
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    let user = JSON.parse(localStorage.getItem('mp_loginuser'));
    if (!user || user === null) {
      this.router.navigate(['/auth/login']);
      return true
    }
    else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(['/auth/login']);
        return true
      }
    }
    const hoursactive = 2;
    let saved = localStorage.getItem('saved');
    if(saved!=null){
      let currentdate = new Date();
      let saveddate = new Date(saved);
      var diff =(currentdate.getTime() - saveddate.getTime()) / 1000;
      diff /= (60 * 60);
      const hours =  Math.abs(Math.round(diff));
      if(hoursactive<=hours){
        localStorage.clear();
        this.router.navigate(['/auth/login']);
        return true
      }else{
        localStorage.setItem('saved', new Date().toString());
      }
    }else{
      localStorage.setItem('saved', new Date().toString());
    }
    let dataroles = (next?.data)?next.data.roles:[];      
    if(dataroles){
      let roleaccess = next.data.roles.some( ai => user.role===ai);
      if(roleaccess != true){
        this.router.navigate(['/auth/login']);
        return true
      }
    } 
    return true
  }
  
}
