import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { ResetPasswordComponent} from "./auth/reset-password/reset-password.component"
import { ForgotPswdComponent } from './auth/forgot-pswd/forgot-pswd.component';
// import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";

import { AdminGuard } from './shared/guard/admin.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AdminGuard],
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth/login',
    component: LoginComponent
  },
  { 
    path: 'reset-password/:token',
    component: ResetPasswordComponent
  },
  { 
    path: 'forgot-password',
    component: ForgotPswdComponent
  },
  {
    path: 'dash',
    component: LoginComponent
  },
  {
    path: 'dashboard',
    canActivate:[AdminGuard],
    data: { roles: [0,1,2]},
    component: ContentComponent,
    loadChildren: () => import('../app/components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: '',
    canActivate: [AdminGuard],
    data: { roles: [0,1,2]},
    component: ContentComponent,
    loadChildren: () => import('../app/bookings/bookings.module').then(m => m.BookingsModule)
  },
  {
    path: '',
    canActivate: [AdminGuard],
    data: { roles: [0,1,2]},
    component: ContentComponent,
    loadChildren: () => import('../app/accounts/accounts.module').then(m => m.AccountsModule)
  },
  {
    path: '',
    canActivate: [AdminGuard],
    data: { roles: [0,1,2]},
    component: ContentComponent,
    loadChildren: () => import('../app/vendors/vendors.module').then(m => m.VendorsModule)
  },
  {
    path: '',
    canActivate: [AdminGuard],
    data: { roles: [0,1,2]},
    component: ContentComponent,
    loadChildren: () => import('../app/vessels/vessels.module').then(m => m.VesselsModule
      )
  },
  {
    path: '',
    canActivate: [AdminGuard],
    data: { roles: [0,1,2]},
    component: ContentComponent,
    loadChildren: () => import('../app/reporting/reporting.module').then(m => m.ReportingModule
      )
  },
  {
    path: '',
    canActivate: [AdminGuard],
    data: { roles: [0,1]},
    component: ContentComponent,
    loadChildren: () => import('../app/users/users.module').then(m => m.UsersModule
      )
  },
  {
    path: '',
    canActivate: [AdminGuard],
    data: { roles: [0,1,2]},
    component: ContentComponent,
    loadChildren: () => import('../app/tracking/tracking.module').then(m => m.TrackingModule
      )
  },

  {
    path: '',
    canActivate: [AdminGuard],
    data: { roles: [0,1,2]},
    component: ContentComponent,
    loadChildren: () => import('../app/delivery-vendor/delivery-vendor.module').then(m => m.DeliveryVendorModule
      )
  },
  {
    path: '',
    canActivate: [AdminGuard],
    data: { roles: [0,1,2]},
    component: ContentComponent,
    loadChildren: () => import('../app/collection-points/collection-points.module').then(m => m.CollectionPointsModule
      )
  },
 
 
  {
    path:'',
    canActivate: [AdminGuard],
    data: { roles: [0,1,2]},
    component:ContentComponent,
    loadChildren:() => import('../app/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path:'',
    // component:ContentComponent,
    loadChildren:() => import('../app/outsidetrack/outsidetrack.module').then(m => m.OutsidetrackModule)
  },
 
  {
    path: '',
    component: FullComponent,
    canActivate: [AdminGuard],
    data: { roles: [0,1,2]},
    // children: full
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
